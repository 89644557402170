<template>
    <!-- ========== Left Sidebar Start ========== -->

    <!--- Sidemenu -->

    <div id="sidebar-menu" class="pt-0">
        <!-- Left Menu Start -->
        <ul id="side-menu" class="metismenu list-unstyled">
            <li>
                <a href="javascript:void(0);">
                    <i class="bx bxs-report"></i>
                    <span>{{ $t('summary') }}</span>
                    <span class="text-muted">
                        <div class="side-summary px-1 mt-1">
                            <div class="d-flex justify-content-between">
                                {{ $t('total agent money') }}
                                <span class="text-white">{{
                                    numberString(
                                        profile.summary.total_agent_balance
                                    )
                                }}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                                {{ $t('total user money') }}
                                <span class="text-white">{{
                                    numberString(
                                        profile.summary.total_player_balance
                                    )
                                }}</span>
                            </div>
                            <hr class="my-2" />
                            <div class="d-flex justify-content-between">
                                {{ $t('honorlink balance') }}
                                <span class="text-white">{{
                                    numberString(
                                        profile.summary.honorlink_balance
                                    )
                                }}</span>
                            </div>
                            <!-- <div class="d-flex justify-content-between">
                                {{ $t('kplay balance') }}
                                <span class="text-white">{{
                                    numberString(
                                        profile.summary.kplay_balance
                                    )
                                }}</span>
                            </div> -->
                            <hr class="my-2" />
                            <div class="d-flex justify-content-between">
                                {{ $t('agent deposit') }}
                                <span class="text-warning">{{
                                    numberString(
                                        profile.summary.online_agent_deposit
                                    )
                                }}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                                {{ $t('agent withdraw') }}
                                <span class="text-danger">{{
                                    numberString(
                                        profile.summary.online_agent_withdraw
                                    )
                                }}</span>
                            </div>
                            <hr class="my-2" />
                            <div class="d-flex justify-content-between">
                                {{ $t('member charging') }}
                                <span class="text-warning">{{
                                    numberString(
                                        profile.summary.online_player_deposit
                                    )
                                }}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                                {{ $t('member exchange') }}
                                <span class="text-danger">{{
                                    numberString(
                                        profile.summary.online_player_withdraw
                                    )
                                }}</span>
                            </div>
                            <hr class="my-2" />
                            <div class="d-flex justify-content-between">
                                {{ $t('today (in and out)') }}
                                <span class="text-primary">{{
                                    numberString(profile.summary.total_income)
                                }}</span>
                            </div>
                            <hr class="my-2" />
                            <div class="d-flex justify-content-between">
                                {{ $t('new player') }}
                                <span class="text-white">{{
                                    numberString(profile.summary.new_player)
                                }}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                                {{ $t('today login') }}
                                <span class="text-white">{{
                                    numberString(profile.summary.login_player)
                                }}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                                {{ $t('live users') }}
                                <span class="text-white">{{
                                    numberString(profile.summary.live_player)
                                }}</span>
                            </div>
                            <hr class="mt-2 mb-0" />
                        </div>
                    </span>
                </a>
            </li>
            <template v-for="item in menuItems">
                <li class="menu-title" v-if="item.isTitle" :key="item.id">
                    {{ $t(item.label) }}
                </li>
                <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
                    <a
                        v-if="hasItems(item)"
                        href="#"
                        class="is-parent"
                        :class="{
                            'has-arrow': !item.badge,
                            'has-dropdown': item.badge,
                        }">
                        <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <span
                            :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                            v-if="item.badge"
                            >{{ $t(item.badge.text) }}</span
                        >
                        <i
                            class="mdi mdi-exclamation-thick text-warning bx-flashing m-0"
                            style="min-width: 0"
                            v-if="
                                (item.label == 'deposit & withdrawal history' &&
                                    profile.notification
                                        .total_transaction_count) ||
                                (item.label == 'bulletin board' &&
                                    profile.notification
                                        .agent_ticket_unread_count + profile.notification
                                        .player_ticket_unread_count) ">
                        </i>
                    </a>

                    <router-link
                        @click="handleReload(item.link)"
                        :to="item.link"
                        v-if="!hasItems(item)"
                        class="side-nav-link-ref position-relative">
                        <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <i
                            class="bx bx-loader bx-spin text-muted p-0 position-absolute"
                            style="min-width: 0"
                            v-if="
                                profile.routeTo == item.link && profile.loading
                            "></i>
                    </router-link>

                    <ul
                        v-if="hasItems(item)"
                        class="sub-menu"
                        aria-expanded="false">
                        <li
                            v-for="(subitem, index) of item.subItems"
                            :key="index">
                            <router-link
                                @click="handleReload(subitem.link)"
                                :to="subitem.link"
                                v-if="!hasItems(subitem) && !subitem.isWindow"
                                class="side-nav-link-ref position-relative"
                                >{{ $t(subitem.label) }}
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .agent_deposit_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .agent_deposit_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        subitem.label == 'store charging' &&
                                        profile.notification
                                            .agent_deposit_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .agent_withdraw_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .agent_withdraw_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        subitem.label == 'store exchange' &&
                                        profile.notification
                                            .agent_withdraw_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .online_player_deposit_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .online_player_deposit_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        subitem.label == 'member charging (online)' &&
                                        profile.notification
                                            .online_player_deposit_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .online_player_withdraw_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .online_player_withdraw_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        subitem.label == 'member exchange (online)' &&
                                        profile.notification
                                            .online_player_withdraw_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .offline_player_deposit_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .offline_player_deposit_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        subitem.label == 'member charging (offline)' &&
                                        profile.notification
                                            .offline_player_deposit_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .offline_player_withdraw_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .offline_player_withdraw_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        subitem.label == 'member exchange (offline)' &&
                                        profile.notification
                                            .offline_player_withdraw_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .agent_ticket_unread_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .agent_ticket_unread_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        item.label == 'bulletin board' &&
                                        subitem.label == 'inquiry of distributor' &&
                                        profile.notification
                                            .agent_ticket_unread_count
                                    "></i>
                                <i
                                    :class="`mdi mdi-numeric-${
                                        profile.notification
                                            .player_ticket_unread_count > 9
                                            ? '9-plus'
                                            : profile.notification
                                                  .player_ticket_unread_count
                                    }-circle text-warning float-end`"
                                    v-if="
                                        item.label == 'bulletin board' &&
                                        subitem.label == 'membership inquiry' &&
                                        profile.notification
                                            .player_ticket_unread_count
                                    "></i>
                                <i
                                    class="bx bx-loader bx-spin text-muted p-0 position-absolute"
                                    style="min-width: 0"
                                    v-if="
                                        profile.routeTo == subitem.link &&
                                        profile.loading
                                    "></i>
                            </router-link>
                            <a
                                v-if="!hasItems(subitem) && subitem.isWindow"
                                href="#"
                                class="side-nav-link-a-ref"
                                @click="
                                    openNewWindow(`/bet/monitor`, `bet-monitor`)
                                "
                                >{{ $t(subitem.label) }}</a
                            >
                            <a
                                v-if="hasItems(subitem)"
                                class="side-nav-link-a-ref has-arrow"
                                href="#"
                                >{{ $t(subitem.label) }}</a
                            >
                            <ul
                                v-if="hasItems(subitem)"
                                class="sub-menu mm-collapse"
                                aria-expanded="false">
                                <li
                                    v-for="(
                                        subSubitem, index
                                    ) of subitem.subItems"
                                    :key="index">
                                    <router-link
                                        :to="subSubitem.link"
                                        :id="subSubitem.id"
                                        class="side-nav-link-ref"
                                        >{{ $t(subSubitem.label) }}
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
        <div class="navbar-brand-box my-3">
            <router-link to="/" class="logo logo-light link-disabled">
                <span class="logo-sm">
                    <img
                        :src="
                            require(`@/assets/images/${client_name}-emblem.png`)
                        "
                        alt
                        height="30" />
                </span>
                <span class="logo-lg">
                    <img
                        :src="
                            require(`@/assets/images/${client_name}-word.png`)
                        "
                        alt />
                </span>
            </router-link>
        </div>
    </div>
    <!-- Sidebar -->
</template>

<script>
import MetisMenu from 'metismenujs/dist/metismenujs';

import {
    menuItems,
    menuItemsOnline,
    menuItemsOffline,
    menuItemsDolwin,
} from './menu';
import number from '@/mixins/number';
import ui from '@/mixins/ui';
import { mapGetters } from 'vuex';
/**
 * Sidenav component
 */
export default {
    mixins: [number, ui],
    data() {
        return {
            menuData: null,
            routerTo: null,
        };
    },
    computed: {
        ...mapGetters('auth', ['profile']),
        brand() {
            return process.env.VUE_APP_BRAND_NAME;
        },
        menuItems() {
            // var items = [];
            // switch (this.player_mode) {
            //     case 'all':
            //         items = menuItems;
            //         break;
            //     case 'online':
            //         items = menuItemsOnline;
            //         break;
            //     case 'offline':
            //         items = menuItemsOffline;
            //         break;
            // }
            // items.forEach((item, index) => {
            //     if (
            //         !['hera'].includes(this.client_name) &&
            //         item.label == 'agent deposit worksheet'
            //     ) {
            //         items.splice(index, 1);
            //     }
            // });
            return menuItems;
        },
    },
    methods: {
        /**
         * Returns true or false if given menu item has child or not
         * @param item menuItem
         */
        hasItems(item) {
            return item.subItems !== undefined
                ? item.subItems.length > 0
                : false;
        },
        openNewWindow(path, name) {
            window.open(
                path,
                name,
                'width=' + 1200 + ',height=' + screen.height / 2
            );
        },
        toggleMenu(event) {
            event.currentTarget.nextElementSibling.classList.toggle('mm-show');
        },
        init() {
            if (document.getElementById('side-menu'))
                new MetisMenu('#side-menu');
            var links = document.getElementsByClassName('side-nav-link-ref');
            var matchingMenuItem = null;
            const paths = [];

            for (var i = 0; i < links.length; i++) {
                paths.push(links[i]['pathname']);
            }
            var itemIndex = paths.indexOf(window.location.pathname);
            if (itemIndex === -1) {
                const strIndex = window.location.pathname.lastIndexOf('/');
                const item = window.location.pathname
                    .substr(0, strIndex)
                    .toString();
                matchingMenuItem = links[paths.indexOf(item)];
            } else {
                matchingMenuItem = links[itemIndex];
            }
            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');
                var parent = matchingMenuItem.parentElement;

                /**
                 * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
                 * We should come up with non hard coded approach
                 */
                if (parent) {
                    parent.classList.add('mm-active');
                    const parent2 = parent.parentElement.closest('ul');
                    if (parent2 && parent2.id !== 'side-menu') {
                        parent2.classList.add('mm-show');

                        const parent3 = parent2.parentElement;
                        if (parent3) {
                            parent3.classList.add('mm-active');
                            var childAnchor =
                                parent3.querySelector('.has-arrow');
                            var childDropdown =
                                parent3.querySelector('.has-dropdown');
                            if (childAnchor)
                                childAnchor.classList.add('mm-active');
                            if (childDropdown)
                                childDropdown.classList.add('mm-active');

                            const parent4 = parent3.parentElement;
                            if (parent4 && parent4.id !== 'side-menu') {
                                parent4.classList.add('mm-show');
                                const parent5 = parent4.parentElement;
                                if (parent5 && parent5.id !== 'side-menu') {
                                    parent5.classList.add('mm-active');
                                    const childanchor =
                                        parent5.querySelector('.is-parent');
                                    if (
                                        childanchor &&
                                        parent5.id !== 'side-menu'
                                    ) {
                                        childanchor.classList.add('mm-active');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        handleReload(path) {
            if (path == this.$route.path) {
                this.$store.commit('increment');
            }
        },
    },

    mounted: function () {
        this.init();
    },
};
</script>
