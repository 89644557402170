<script>
import router from '@/router';
import { layoutComputed } from '@/state/helpers';

import NavBar from '@/components/nav-bar';
import NavBarDolwin from '@/components/nav-bar-dolwin';
import SideBar from '@/components/side-bar';
// import RightBar from '@/components/right-bar';
import Footer from '@/components/footer';
import ui from '@/mixins/ui';
import { mapGetters } from 'vuex';
/**
 * Vertical layout
 */
export default {
    components: {
        NavBar,
        NavBarDolwin,
        SideBar,
        // RightBar,
        Footer,
    },
    mixins: [ui],
    data() {
        return {
            type: this.$store
                ? this.$store.state.layout.leftSidebarType
                : null || null,
            width: this.$store
                ? this.$store.state.layout.layoutWidth
                : null || null,
            isMenuCondensed: false,
            loader: true,
        };
    },
    computed: {
        ...layoutComputed,
        ...mapGetters('auth', ['profile']),
    },
    created: () => {
        document.body.removeAttribute('data-layout', 'horizontal');
        document.body.removeAttribute('data-topbar', 'dark');
        document.body.removeAttribute('data-layout-size', 'boxed');
        document.body.classList.remove('auth-body-bg');
    },
    methods: {
        toggleMenu() {
            document.body.classList.toggle('sidebar-enable');

            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                    document.body.classList.remove('vertical-collpsed');
                });
                document.body.classList.toggle('vertical-collpsed');
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                });
                document.body.classList.remove('vertical-collpsed');
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        toggleRightSidebar() {
            document.body.classList.toggle('right-bar-enabled');
        },
        hideRightSidebar() {
            document.body.classList.remove('right-bar-enabled');
        },
    },
    mounted() {
        // if (this.profile.loading) {
        //     document.getElementById('preloader').style.display = 'block';
        //     document.getElementById('status').style.display = 'block';
        //     setTimeout(function () {
        //         document.getElementById('preloader').style.display = 'none';
        //         document.getElementById('status').style.display = 'none';
        //     }, 2500);
        // } else {
        //     document.getElementById('preloader').style.display = 'none';
        //     document.getElementById('status').style.display = 'none';
        // }
    },
};
</script>

<template>
    <div>
        <!-- <div id="preloader" v-if="profile.loading">
            <div id="status">
                <div class="spinner-chase">
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                </div>
            </div>
        </div> -->
        <div id="layout-wrapper">
            <NavBarDolwin v-if="client_name == 'dolwin'" />
            <NavBar v-else />
            <SideBar
                :is-condensed="isMenuCondensed"
                :type="leftSidebarType"
                :width="layoutWidth" />
            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="main-content">
                <div class="page-content">
                    <!-- Start Content-->
                    <div class="container-fluid">
                        <slot />
                    </div>
                </div>
                <!-- <Footer /> -->
            </div>
            <RightBar />
        </div>
    </div>
</template>
