<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img
                                src="@/assets/images/logo.svg"
                                alt
                                height="22" />
                        </span>
                        <span class="logo-lg">
                            <img
                                src="@/assets/images/logo-dark.png"
                                alt
                                height="17" />
                        </span>
                    </router-link>
                    <router-link to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img
                                :src="
                                    require(`@/assets/images/${brand}-emblem.png`)
                                "
                                alt
                                height="40" />
                        </span>
                        <span class="logo-lg">
                            <img
                                :src="
                                    require(`@/assets/images/${brand}-word.png`)
                                "
                                alt
                                height="60" />
                        </span>
                    </router-link>
                </div>

                <button
                    id="vertical-menu-btn"
                    type="button"
                    class="btn btn-sm px-3 font-size-16 header-item"
                    @click="toggleMenu">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>
            <div
                class="d-flex flex-nowrap gap-4 flex-grow-1 notification-links">
                <button
                    @click="goTo('/agent/transaction/deposit')"
                    :class="{
                        'text-info': profile.notification.offline_deposit_count,
                    }"
                    class="btn fw-bold header-item">
                    {{ $t('agent deposit') }}
                    <i
                        v-if="profile.notification.offline_deposit_count"
                        :class="`mdi mdi-numeric-${
                            profile.notification.offline_deposit_count > 9
                                ? '9-plus'
                                : profile.notification.offline_deposit_count
                        }-circle text-danger fs-4 position-absolute`"
                        style="top: 10px"></i>
                </button>
                <button
                    @click="goTo('/agent/transaction/withdraw')"
                    :class="{
                        'text-info':
                            profile.notification.offline_withdraw_count,
                    }"
                    class="btn fw-bold header-item">
                    {{ $t('agent withdraw') }}
                    <i
                        v-if="profile.notification.offline_withdraw_count"
                        :class="`mdi mdi-numeric-${
                            profile.notification.offline_withdraw_count > 9
                                ? '9-plus'
                                : profile.notification.offline_withdraw_count
                        }-circle text-danger fs-4 position-absolute`"
                        style="top: 10px"></i>
                </button>
                <button
                    v-if="['all', 'online'].includes(player_mode)"
                    @click="goTo('/player/transaction/deposit/online')"
                    :class="{
                        'text-info': profile.notification.online_deposit_count,
                    }"
                    class="btn fw-bold header-item">
                    {{ $t('member charging') }}
                    <i
                        v-if="profile.notification.online_deposit_count"
                        :class="`mdi mdi-numeric-${
                            profile.notification.online_deposit_count > 9
                                ? '9-plus'
                                : profile.notification.online_deposit_count
                        }-circle text-danger fs-4 position-absolute`"
                        style="top: 10px"></i>
                </button>
                <button
                    v-if="['all', 'online'].includes(player_mode)"
                    @click="goTo('/player/transaction/withdraw/online')"
                    :class="{
                        'text-info': profile.notification.online_withdraw_count,
                    }"
                    class="btn fw-bold header-item">
                    {{ $t('member exchange') }}
                    <i
                        v-if="profile.notification.online_withdraw_count"
                        :class="`mdi mdi-numeric-${
                            profile.notification.online_withdraw_count > 9
                                ? '9-plus'
                                : profile.notification.online_withdraw_count
                        }-circle text-danger fs-4 position-absolute`"
                        style="top: 10px"></i>
                </button>
                <button
                    @click="goTo('/tickets/agent?status=open')"
                    :class="{
                        'text-info':
                            profile.notification.agent_ticket_unread_count,
                    }"
                    class="btn fw-bold header-item">
                    {{ $t('customer service') }}
                    <span v-if="['all', 'online'].includes(player_mode)"
                        >({{ $t('agent') }})</span
                    >
                    <i
                        v-if="profile.notification.agent_ticket_unread_count"
                        :class="`mdi mdi-numeric-${
                            profile.notification.agent_ticket_unread_count > 9
                                ? '9-plus'
                                : profile.notification.agent_ticket_unread_count
                        }-circle text-danger fs-4 position-absolute`"
                        style="top: 10px"></i>
                </button>
                <button
                    v-if="['all', 'online'].includes(player_mode)"
                    @click="goTo('/tickets/player?status=open')"
                    :class="{
                        'text-info':
                            profile.notification.player_ticket_unread_count,
                    }"
                    class="btn fw-bold header-item">
                    {{ $t('customer service') }} ({{ $t('player') }})
                    <i
                        v-if="profile.notification.player_ticket_unread_count"
                        :class="`mdi mdi-numeric-${
                            profile.notification.player_ticket_unread_count > 9
                                ? '9-plus'
                                : profile.notification.player_ticket_unread_count
                        }-circle text-danger fs-4 position-absolute`"
                        style="top: 10px"></i>
                </button>
            </div>

            <div class="d-flex">
                <b-dropdown
                    class="d-lg-inline-block noti-icon flags"
                    right
                    toggle-class="header-item noti-icon"
                    variant="black">
                    <template v-slot:button-content>
                        <div>
                            <img width="26" :src="current_locale_flag" alt="" />
                        </div>
                    </template>
                    <b-dropdown-item
                        v-for="(locale, i) in languages"
                        :key="`locale-${i}`"
                        @click="changeLocale(locale.language)"
                        :active="locale.language == $i18n.locale">
                        <img
                            width="18"
                            :src="locale.flag"
                            alt=""
                            class="me-3" />
                        {{ $t(locale.title) }}</b-dropdown-item
                    >
                </b-dropdown>

                <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
                    <button
                        type="button"
                        class="btn fw-bold header-item noti-icon"
                        @click="initFullScreen">
                        <i class="bx bx-fullscreen"></i>
                    </button>
                </div> -->

                <b-dropdown
                    right
                    menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                    toggle-class="header-item noti-icon"
                    variant="black">
                    <template v-slot:button-content>
                        <i
                            class="bx bx-bell"
                            :class="{
                                'bxs-bell-ring bx-tada alarm':
                                    notifications.length,
                            }"></i>
                        <span
                            v-if="notifications.length"
                            class="badge bg-danger rounded-pill"
                            >{{ numberString(notifications.length) }}</span
                        >
                    </template>

                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">
                                    {{ $t('notifications') }}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <SimpleBar style="max-height: 230px">
                        <router-link
                            v-show="notifications.length"
                            @click="handleClick(n, i)"
                            :to="n.link"
                            class="text-reset notification-item"
                            v-for="(n, i) in notifications"
                            :key="i">
                            <div class="d-flex">
                                <div class="avatar-xs me-3">
                                    <span
                                        :class="`avatar-title bg-${n.color} rounded-circle font-size-16`">
                                        <i :class="`bx ${n.icon}`"></i>
                                    </span>
                                </div>
                                <div class="flex-grow-1">
                                    <h6 class="mt-0 mb-1 fw-bold">
                                        {{ n.username }}
                                    </h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">
                                            {{ $t(n.event) }}
                                            <span
                                                v-if="
                                                    n.value &&
                                                    !n.event ===
                                                        'new membership registration'
                                                ">
                                                {{ n.value }}</span
                                            >
                                        </p>
                                        <p class="mb-0">
                                            <i
                                                class="mdi mdi-clock-outline"></i>
                                            {{ dateYmD(n.timestamp, true) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </router-link>

                        <div
                            v-show="!notifications.length"
                            class="text-reset notification-item">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">
                                            {{
                                                $t(
                                                    'you have no notification for this time'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SimpleBar>
                    <div class="p-2 border-top d-grid">
                        <a
                            class="btn btn-sm btn-link font-size-14 text-center text-danger"
                            href="javascript:void(0)"
                            :class="{ disabled: !notifications.length }"
                            @click="$store.commit('clearNotifications')">
                            {{ $t('clear all') }}
                        </a>
                    </div>
                </b-dropdown>
                <b-dropdown right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <img
                            class="rounded-circle header-profile-user"
                            src="@/assets/images/admin-logo.png"
                            alt="Header Avatar" />
                        <span class="d-none d-xl-inline-block ms-1">
                            <div>{{ profile.username }}</div>
                        </span>
                        <i
                            class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- <router-link
                        to="/profile"
                        class="dropdown-item"
                    >
                        <i class="bx bx-user font-size-16 align-middle me-1"></i>
                        {{ $t("profile") }}
                    </router-link>
                    <b-dropdown-divider></b-dropdown-divider> -->
                    <a
                        href="javascript:void(0)"
                        class="dropdown-item"
                        @click="password_modal = true">
                        <i
                            class="bx bx-shield font-size-16 align-middle me-1"></i>
                        {{ $t('change password') }}
                    </a>
                    <a
                        href="javascript:void(0)"
                        class="dropdown-item"
                        @click="pin_modal = true">
                        <i
                            class="bx bx-shield-quarter font-size-16 align-middle me-1"></i>
                        {{ $t('change pin') }}
                    </a>
                    <a
                        href="javascript:void(0)"
                        class="dropdown-item text-danger fw-bold"
                        @click="logout()">
                        <i
                            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                        {{ $t('logout') }}
                    </a>
                </b-dropdown>

                <!-- <div class="dropdown d-inline-block">
                    <button
                        type="button"
                        class="btn fw-bold header-item noti-icon right-bar-toggle toggle-right"
                        @click="toggleRightSidebar">
                        <i class="bx bx-cog bx-spin toggle-right"></i>
                    </button>
                </div> -->
            </div>
        </div>
        <!-- <audio class="hidden-audio" ref="audioPlayer" controls>
            <source
                :src="require('@/assets/audio/notification.mp3')"
                type="audio/mp3" />
        </audio> -->
    </header>
    <b-modal
        v-model="password_modal"
        @hidden="reset()"
        centered
        :title="$t('change password')"
        title-class="font-18"
        hide-footer>
        <b-form>
            <table class="table table-bordered table-wrap table-form mb-2">
                <tbody>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('current password') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                v-model="pl_password.current_password"
                                type="password"
                                class="form-control"
                                :placeholder="$t('current password')"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        v$.pl_password.current_password.$error,
                                }" />
                            <div
                                v-for="(item, index) in v$.pl_password
                                    .current_password.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('new password') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                v-model="pl_password.new_password"
                                type="password"
                                class="form-control"
                                :placeholder="$t('new password')"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        v$.pl_password.new_password.$error,
                                }" />
                            <div
                                v-for="(item, index) in v$.pl_password
                                    .new_password.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('confirm password') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                v-model="pl_password.confirm_password"
                                type="password"
                                class="form-control"
                                :placeholder="$t('confirm password')"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        v$.pl_password.confirm_password.$error,
                                }" />
                            <div
                                v-for="(item, index) in v$.pl_password
                                    .confirm_password.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('current pin') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                type="password"
                                class="form-control w-50"
                                :placeholder="$t('current pin')"
                                maxlength="4"
                                v-model="pl_password.current_pin"
                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13; "
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        v$.pl_password.current_pin.$error,
                                }" />
                            <div
                                v-for="(item, index) in v$.pl_password
                                    .current_pin.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <button
                class="btn btn-warning float-end"
                @click.prevent="updatePassword()"
                :disabled="loading">
                {{ $t('submit') }}
            </button>
        </b-form>
    </b-modal>
    <b-modal
        v-model="pin_modal"
        @hidden="reset()"
        centered
        :title="$t('change pin')"
        title-class="font-18"
        hide-footer
        size="sm">
        <b-form>
            <table class="table table-bordered table-wrap table-form mb-2">
                <tbody>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('current pin') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                type="password"
                                class="form-control"
                                :placeholder="$t('current pin')"
                                maxlength="4"
                                v-model="pl_pin.current_pin"
                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13; "
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        v$.pl_pin.current_pin.$error,
                                }" />
                            <div
                                v-for="(item, index) in v$.pl_pin.current_pin
                                    .$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('new pin') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                v-model="pl_pin.new_pin"
                                type="password"
                                class="form-control"
                                :placeholder="$t('new pin')"
                                :class="{
                                    'is-invalid':
                                        submitted && v$.pl_pin.new_pin.$error,
                                }"
                                maxlength="4"
                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13; " />

                            <div
                                v-for="(item, index) in v$.pl_pin.new_pin
                                    .$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft">
                            {{ $t('confirm pin') }}
                        </th>
                        <td>
                            <input
                                autocomplete="new-password"
                                v-model="pl_pin.confirm_pin"
                                type="password"
                                class="form-control"
                                :placeholder="$t('confirm pin')"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        v$.pl_pin.confirm_pin.$error,
                                }"
                                maxlength="4"
                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13; " />
                            <div
                                v-for="(item, index) in v$.pl_pin.confirm_pin
                                    .$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button
                class="btn btn-warning float-end"
                @click.prevent="updatePIN()"
                :disabled="loading">
                {{ $t('submit') }}
            </button>
        </b-form>
    </b-modal>
</template>
<script>
import { SimpleBar } from 'simplebar-vue3';
import { mapActions, mapGetters } from 'vuex';
import number from '../mixins/number';
import ui from '../mixins/ui';
import datetime from '../mixins/datetime';
import audio from '../mixins/audio';
import $cookies from 'vue-cookies';
import Swal from 'sweetalert2';
import {
    required,
    requiredIf,
    minLength,
    maxLength,
    sameAs,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const alphaNumericOnly = (value) => {
    const regex = /^[A-Za-z0-9]+$/;
    return helpers.req(value) && regex.test(value);
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: { SimpleBar },
    mixins: [number, datetime, audio, ui],
    data() {
        return {
            lang: 'en',
            text: null,
            flag: null,
            value: null,
            languages: [
                {
                    flag: require('@/assets/images/flags/us.jpg'),
                    language: 'en',
                    title: 'English',
                },
                {
                    flag: require('@/assets/images/flags/korea.jpg'),
                    language: 'ko',
                    title: 'Korean',
                },
            ],
            submitted: false,
            loading: false,
            password_modal: false,
            pl_password: {
                current_password: null,
                new_password: null,
                confirm_password: null,
                current_pin: null,
            },
            pin_modal: false,
            pl_pin: {
                current_pin: null,
                new_pin: null,
                confirm_pin: null,
            },
        };
    },
    validations() {
        return {
            pl_password: {
                current_password: {
                    required: helpers.withMessage('required', required),
                },
                new_password: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                confirm_password: {
                    sameAs: helpers.withMessage(
                        `password doesn't match`,
                        sameAs(this.pl_password.new_password)
                    ),
                },
                current_pin: {
                    required: helpers.withMessage('required', required),
                },
            },
            pl_pin: {
                current_pin: {
                    required: helpers.withMessage('required', required),
                },
                new_pin: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                },
                confirm_pin: {
                    sameAs: helpers.withMessage(
                        `pin doesn't match`,
                        sameAs(this.pl_pin.new_pin)
                    ),
                },
            },
        };
    },
    computed: {
        // ...authComputed,
        current_locale_flag() {
            var flag;
            switch (this.$i18n.locale) {
                case 'en':
                    flag = require('@/assets/images/flags/us.jpg');
                    break;
                case 'ko':
                    flag = require('@/assets/images/flags/korea.jpg');
                    break;
            }
            return flag;
        },
        ...mapGetters('auth', ['profile', 'authenticated', 'pusher_channel']),
        brand() {
            return process.env.VUE_APP_BRAND_NAME;
        },
        notifications() {
            return this.$store.state.notifications;
        },
    },

    methods: {
        ...mapActions('auth', {
            authLogout: 'logoutUser',
            authGetProfile: 'getProfile',
            authUpdateProfile: 'updateProfile',
        }),
        async updatePassword() {
            this.submitted = true;
            this.v$.pl_password.$touch();
            if (this.v$.pl_password.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure')}?`,
                    html: `${this.$t('change password')}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });
                if (confirmed) {
                    this.loading = true;
                    const res = await this.authUpdateProfile(this.pl_password);
                    this.loading = false;
                    if (res) {
                        Swal.fire({
                            title: this.$t(res.data.message),
                            icon: res.data.status,
                            timer: 2000,
                        });
                        if (res.success) {
                            this.password_modal = false;
                        }
                    }
                }
            }
        },
        async updatePIN() {
            this.submitted = true;
            this.v$.pl_pin.$touch();
            if (this.v$.pl_pin.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure')}?`,
                    html: `${this.$t('change pin')}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });
                if (confirmed) {
                    this.loading = true;
                    const res = await this.authUpdateProfile(this.pl_pin);
                    this.loading = false;
                    if (res) {
                        Swal.fire({
                            title: this.$t(res.data.message),
                            icon: res.data.status,
                            timer: 2000,
                        });
                        if (res.success) {
                            this.pin_modal = false;
                        }
                    }
                }
            }
        },
        async logout() {
            await this.authLogout();
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle('fullscreen-enable');
            if (
                !document.fullscreenElement &&
                /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        changeLocale(lang) {
            // alert(lang);
            this.$i18n.locale = lang;
            $cookies.set('locale', lang);
        },
        goTo(path) {
            this.$router.push(path);
        },

        reset() {
            this.submitted = false;

            this.pl_password.current_password = null;
            this.pl_password.new_password = null;
            this.pl_password.confirm_password = null;
            this.pl_password.current_pin = null;

            this.pl_pin.current_pin = null;
            this.pl_pin.new_pin = null;
            this.pl_pin.confirm_pin = null;
        },
        handleClick(n, i) {
            this.$store.commit('removeNotificationByIndex', i);
            if (n.event === 'new membership registration') {
                this.goToUserForm(n.value, 'player');
            }
        },
    },

    mounted() {
        this.initializeAudio();
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('new-deposit', (e) => {
                this.playAudio('deposit');
                this.$store.commit('addNotification', {
                    color: 'success',
                    icon: 'bx-credit-card',
                    username: e.message.username,
                    event: `deposit`,
                    value: this.numberString(e.message.amount),
                    timestamp: new Date(),
                    link:
                        e.message.role == 'player'
                            ? '/player/transaction/deposit/online'
                            : '/agent/transaction/deposit',
                });
                this.authGetProfile();
            });
            window.PusherChannel.bind('new-withdraw', (e) => {
                this.playAudio('withdraw');
                this.$store.commit('addNotification', {
                    color: 'danger',
                    icon: 'bx-credit-card',
                    username: e.message.username,
                    event: `withdraw`,
                    value: this.numberString(e.message.amount),
                    timestamp: new Date(),
                    link:
                        e.message.role == 'player'
                            ? '/player/transaction/withdraw/online'
                            : '/agent/transaction/withdraw',
                });
                this.authGetProfile();
            });
            window.PusherChannel.bind('new-ticket', (e) => {
                this.playAudio();
                this.$store.commit('addNotification', {
                    color: 'info',
                    icon: 'bxs-coupon',
                    username: e.message.username,
                    event: `created a ticket`,
                    value: `"${e.message.subject}"`,
                    timestamp: new Date(),
                    link: `/tickets/${e.message.role}?status=open`,
                });
                this.authGetProfile();
            });
            window.PusherChannel.bind('new-ticket-reply', (e) => {
                this.playAudio();
                this.$store.commit('addNotification', {
                    color: 'info',
                    icon: 'bxs-message-rounded-dots',
                    username: e.message.username,
                    event: 'replied to a ticket',
                    timestamp: new Date(),
                    link: `/tickets/${e.message.role}?status=open`,
                });
                this.authGetProfile();
            });
            // window.PusherChannel.bind('agent-view-bank', (e) => {
            //     this.playAudio();
            //     this.$store.commit('addNotification', {
            //         color: 'success',
            //         icon: 'bxs-bank',
            //         username: e.message.username,
            //         event: 'viewed handler bank details',
            //         timestamp: new Date(),
            //         link: '#',
            //     });
            // });
            // window.PusherChannel.bind('view-bank', (e) => {
            //     this.playAudio();
            //     this.$store.commit('addNotification', {
            //         color: 'primary',
            //         icon: 'bxs-bank',
            //         username: e.message.username,
            //         event: 'viewed handler bank details',
            //         timestamp: new Date(),
            //         link: '#',
            //     });
            // });
            window.PusherChannel.bind('convert-points', (e) => {
                this.playAudio();
                this.$store.commit('addNotification', {
                    color: 'warning',
                    icon: 'bxs-star',
                    username: e.message.username,
                    event: `converted points`,
                    value: this.numberString(e.message.amount),
                    timestamp: new Date(),
                    link: '/log/asset/player/balance/all',
                });
            });
            window.PusherChannel.bind('claim-coupon', (e) => {
                this.playAudio();
                this.$store.commit('addNotification', {
                    color: 'info',
                    icon: 'bxs-coupon',
                    username: e.message.username,
                    event: `claimed coupon`,
                    value: this.numberString(e.message.amount),
                    timestamp: new Date(),
                    link: '/coupon/agent',
                });
            });
            window.PusherChannel.bind('new-player-registration', (e) => {
                this.playAudio();
                this.$store.commit('addNotification', {
                    color: 'dark',
                    icon: 'bxs-user-plus',
                    username: e.message.username,
                    event: `new membership registration`,
                    value: e.message.id,
                    timestamp: new Date(),
                    link: '#',
                });
                this.authGetProfile();
            });
        }
    },
    beforeUnmount() {
        this.resetAudio();
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('new-deposit');
            window.PusherChannel.unbind('new-withdraw');
            window.PusherChannel.unbind('new-ticket');
            window.PusherChannel.unbind('new-ticket-reply');
            window.PusherChannel.unbind('agent-view-bank');
            window.PusherChannel.unbind('view-bank');
            window.PusherChannel.unbind('convert-points');
            window.PusherChannel.unbind('claim-coupon');
            window.PusherChannel.unbind('new-player-registration');
        }
    },
};
</script>
