<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

export default {
    components: {
        Vertical,
        Horizontal,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
    mounted() {
        // document.querySelector("html").setAttribute('dir', 'rtl');
    },
};
</script>

<template>
    <div>
        <Vertical
            v-if="layoutType === 'vertical' && !$route.query.window"
            :layout="layoutType"
        >
            <slot />
        </Vertical>
        <div v-else>
            <div class="layout-wrapper">
                <div style="height: 100vh;">
                    <div class="page-content" style="padding-top: 5px !important">
                        <div class="container-fluid">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot />
        </Horizontal> -->
    </div>
</template>

<style scoped>
.page-content {
    padding: 24px calc(24px / 2) 24px calc(24px / 2);
}
</style>